// src/App.js
import React, { useState, useEffect } from 'react';
import './App.css';
import logo from "./assets/meh.png";
import logoTrans from "./assets/logo.png";
import logo1 from "./assets/meh-1.png";

function App() {
  const [logos, setLogos] = useState([]);
  const [images, setImages] = useState([]);

  // Function to generate random images at random locations
  const generateRandomImages = () => {
    const newImages = [];

    for (let i = 0; i < 10; i++) { // Create 10 random images
      const startX = Math.random() * window.innerWidth; // Random X position
      const startY = Math.random() * window.innerHeight; // Random Y position
      const endX = Math.random() < 0.5 ? '-100%' : '100%'; // Random X direction (left or right)
      const endY = Math.random() < 0.5 ? '-100%' : '100%'; // Random Y direction (up or down)

      newImages.push({
        id: i,
        startX,
        startY,
        endX,
        endY,
      });
    }

    setImages(newImages);

    // Remove the images after 2 seconds
    setTimeout(() => {
      setImages([]);
    }, 3000);
  };

  useEffect(() => {
    // Call the generate function every 3 seconds
    const interval = setInterval(generateRandomImages, 5000);
    return () => clearInterval(interval); // Cleanup the interval
  }, []);

  const handleClick = (e) => {
    const logoArray = [];
    const { clientX, clientY } = e;

    // Create multiple logos on click
    for (let i = 0; i < 20; i++) {
      const endX = (Math.random() * 2 - 1) * 400; // Random X direction
      const endY = (Math.random() * 2 - 1) * 400; // Random Y direction
      logoArray.push({
        id: i,
        startX: clientX,
        startY: clientY,
        endX,
        endY,
      });
    }

    setLogos(logoArray);
  };

  return (
    <div className="App" onClick={handleClick}>
      {logos.map((logo) => (
        <div
          key={logo.id}
          className="logo"
          style={{
            left: `${logo.startX}px`,
            top: `${logo.startY}px`,
            '--x': `${logo.endX}px`,
            '--y': `${logo.endY}px`,
          }}
        ></div>
      ))}
      {images.map((image) => (
        <div
          key={image.id}
          className="random-image"
          style={{
            left: `${image.startX}px`,
            top: `${image.startY}px`,
            '--x': image.endX,
            '--y': image.endY,
            backgroundImage: `url(${logoTrans})`, // You can replace this URL with your actual image
          }}
        ></div>
      ))}
      <div id="bg-wrap">
        <svg viewBox="0 0 100 100" preserveAspectRatio="xMidYMid slice">
          <defs>
            <radialGradient id="Gradient1" cx="50%" cy="50%" fx="0.441602%" fy="50%" r=".5">
              <animate attributeName="fx" dur="34s" values="0%;3%;0%" repeatCount="indefinite"></animate>
              <stop offset="0%" stopColor="rgba(255, 0, 255, 1)"></stop>
              <stop offset="100%" stopColor="rgba(255, 0, 255, 0)"></stop>
            </radialGradient>
            <radialGradient id="Gradient2" cx="50%" cy="50%" fx="2.68147%" fy="50%" r=".5">
              <animate attributeName="fx" dur="23.5s" values="0%;3%;0%" repeatCount="indefinite"></animate>
              <stop offset="0%" stopColor="rgba(255, 255, 0, 1)"></stop>
              <stop offset="100%" stopColor="rgba(255, 255, 0, 0)"></stop>
            </radialGradient>
            <radialGradient id="Gradient3" cx="50%" cy="50%" fx="0.836536%" fy="50%" r=".5">
              <animate attributeName="fx" dur="21.5s" values="0%;3%;0%" repeatCount="indefinite"></animate>
              <stop offset="0%" stopColor="rgba(0, 255, 255, 1)"></stop>
              <stop offset="100%" stopColor="rgba(0, 255, 255, 0)"></stop>
            </radialGradient>
            <radialGradient id="Gradient4" cx="50%" cy="50%" fx="4.56417%" fy="50%" r=".5">
              <animate attributeName="fx" dur="23s" values="0%;5%;0%" repeatCount="indefinite"></animate>
              <stop offset="0%" stopColor="rgba(0, 255, 0, 1)"></stop>
              <stop offset="100%" stopColor="rgba(0, 255, 0, 0)"></stop>
            </radialGradient>
            <radialGradient id="Gradient5" cx="50%" cy="50%" fx="2.65405%" fy="50%" r=".5">
              <animate attributeName="fx" dur="24.5s" values="0%;5%;0%" repeatCount="indefinite"></animate>
              <stop offset="0%" stopColor="rgba(0,0,255, 1)"></stop>
              <stop offset="100%" stopColor="rgba(0,0,255, 0)"></stop>
            </radialGradient>
            <radialGradient id="Gradient6" cx="50%" cy="50%" fx="0.981338%" fy="50%" r=".5">
              <animate attributeName="fx" dur="25.5s" values="0%;5%;0%" repeatCount="indefinite"></animate>
              <stop offset="0%" stopColor="rgba(255,0,0, 1)"></stop>
              <stop offset="100%" stopColor="rgba(255,0,0, 0)"></stop>
            </radialGradient>
          </defs>
          <rect x="13.744%" y="1.18473%" width="100%" height="100%" fill="url(#Gradient1)" transform="rotate(334.41 50 50)">
            <animate attributeName="x" dur="20s" values="25%;0%;25%" repeatCount="indefinite"></animate>
            <animate attributeName="y" dur="21s" values="0%;25%;0%" repeatCount="indefinite"></animate>
            <animateTransform attributeName="transform" type="rotate" from="0 50 50" to="360 50 50" dur="7s" repeatCount="indefinite"></animateTransform>
          </rect>
          <rect x="-2.17916%" y="35.4267%" width="100%" height="100%" fill="url(#Gradient2)" transform="rotate(255.072 50 50)">
            <animate attributeName="x" dur="23s" values="-25%;0%;-25%" repeatCount="indefinite"></animate>
            <animate attributeName="y" dur="24s" values="0%;50%;0%" repeatCount="indefinite"></animate>
            <animateTransform attributeName="transform" type="rotate" from="0 50 50" to="360 50 50" dur="12s" repeatCount="indefinite"></animateTransform>
          </rect>
          <rect x="9.00483%" y="14.5733%" width="100%" height="100%" fill="url(#Gradient3)" transform="rotate(139.903 50 50)">
            <animate attributeName="x" dur="25s" values="0%;25%;0%" repeatCount="indefinite"></animate>
            <animate attributeName="y" dur="12s" values="0%;25%;0%" repeatCount="indefinite"></animate>
            <animateTransform attributeName="transform" type="rotate" from="360 50 50" to="0 50 50" dur="9s" repeatCount="indefinite"></animateTransform>
          </rect>
        </svg>
      </div>
      <div className='home-text'>
        <span className='highlight-bg'>$MEH </span>
      </div>

      {/* <div className="container">
          <div className="box">
            <div className="spin-container">
              <div className="shape">
                <div className="bd"></div>
              </div>
            </div>
          </div>
        </div> */}

      <div className="image-container">
        <div className="image-hover">
          <img src={logo} alt="meh" className="normal-img" />
          <img src={logo1} alt="Funny Image" className="hover-img" />
        </div>



        {/* <div className='tag-line'>
          <span className='highlight-box'>the most famous Japanese haircat live on Solana</span>
        </div> */}

      </div>
      <div className='text'>
        <h1>Dogs, AI, or rug...</h1>
        <h1>whatever, human</h1>
        <h1>This CA is all you need</h1>
        <h1>3MQX2W6kmGMuEQEebZr8tCKfd4AwTpPT5p7ngcD2pump</h1>
        <a href='https://pump.fun/3MQX2W6kmGMuEQEebZr8tCKfd4AwTpPT5p7ngcD2pump' rel="noreferrer" target='_blank'><button className="buy-button">Boost the Mood.. or Meh</button></a>
        <iframe
          src="https://www.urbandictionary.com/define.php?term=Meh&defid=10190"
          title="what is meh?"
          width="100%"
          height="500px"
          style={{ border: 'none', marginTop: '50px' }}
        ></iframe>
        <div className='linked'>
          {/* <a href='https://raydium.io/swap/?outputMint=#&inputMint=sol' rel="noreferrer" target='_blank'><button className="buy-button">Take Some 😻</button></a>
      <div className='linked'> */}
          {/* <a href='https://x.com/omame_haircat' rel="noreferrer" target='_blank'><button className="link-button"><i class="fab fa-square-x-twitter"></i></button></a>
          <a href='https://www.instagram.com/cici.toto.mametchi/' rel="noreferrer" target='_blank'><button className="link-button"><i class="fab fa-instagram"></i></button></a>

          <a href='https://pump.fun/#######' rel="noreferrer" target='_blank'><button className="link-button"><i class="fa-solid fa-chart-line"></i></button></a> */}
        </div>
      </div>
      {/* <Linked /> */}
      {/* <Tokenomics /> */}
      {/* <Tuts /> */}
      {/* <Roadmap /> */}
      {/* <Explore /> */}
    </div>
  );
}

export default App;